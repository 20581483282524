// src/TextGenerator.js
import React, { useState } from 'react';
import { generateText } from './palmApi';

const TextGenerator = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');

  const handleGenerateText = async () => {
    try {
      const response = await generateText(inputText);
      setOutputText(response?.candidates[0]?.output || 'No output generated');
    } catch (error) {
      setOutputText('Error generating text');
    }
  };

  return (
    <div>
      <h1>Google Generative AI Text Generator</h1>
        <df-messenger
        oauth-client-id="INSERT_OAUTH_CLIENT_ID"
        location="us-central1"
        project-id="accessibiltiy"
        agent-id="8e81f2ec-68a1-4486-9182-40768fefee89"
        language-code="en"
        max-query-length="-1">
        <df-messenger-chat-bubble
        chat-title="ReservePlaceAgent">
        </df-messenger-chat-bubble>
        </df-messenger>
    </div>
  );
};

export default TextGenerator;
