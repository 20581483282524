import React from 'react';
import { 
  SendHorizontal, 
  Users, 
  MessageSquare, 
  Calculator, 
  Award, 
  CheckCircle 
} from 'lucide-react';

const ProcessCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white rounded-3xl shadow-md mb-6 p-8 hover:shadow-lg transition-shadow">
    <div className="flex items-start">
      <div className="bg-purple-100 p-4 rounded-2xl mr-6">
        <Icon className="w-6 h-6 text-purple-600" strokeWidth={1.5} />
      </div>
      <div>
        <h2 className="text-xl font-semibold text-gray-900 mb-3">{title}</h2>
        <p className="text-gray-600 text-lg leading-relaxed">{description}</p>
      </div>
    </div>
  </div>
);

function RFP() {
  const processes = [
    {
      icon: SendHorizontal,
      title: "Automated RFP Distribution",
      description: "After finalizing your service cart, the AI centralizes the Request for Proposal (RFP) process by sending a single RFP to all selected service providers."
    },
    {
      icon: MessageSquare,
      title: "Simplified Communication",
      description: "The AI handles vendor responses and manages all communications, streamlining the entire process for your convenience."
    },
    {
      icon: Calculator,
      title: "Quote Collection",
      description: "It automatically collects quotes from vendors, saving you significant time and effort in gathering pricing information."
    },
    {
      icon: Award,
      title: "Smart Analysis",
      description: "The AI highlights the best options based on multiple factors including price, customer reviews, and special offers."
    },
    {
      icon: CheckCircle,
      title: "Easy Comparison",
      description: "This streamlined approach makes it easy to compare vendors and choose the best fit for your event."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-6 py-16 bg-gray-50">
      <div className="text-center mb-16">
        <h1 className="text-5xl font-bold text-purple-600 mb-6" style={{ color: '#8A3FFC' }}>
          Centralized RFP Process
        </h1>
        <p className="text-2xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
          Simplify your vendor selection process with our AI-powered RFP management system.
        </p>
      </div>

      {/* Process Timeline */}
      <div className="relative max-w-3xl mx-auto">
        {/* Vertical Timeline Line */}
        <div className="absolute left-8 top-0 bottom-0 w-0.5 bg-purple-200 hidden md:block" />

        {/* Process Cards */}
        <div className="space-y-8">
          {processes.map((process, index) => (
            <div key={index} className="relative">
              {/* Timeline Dot */}
              <div className="absolute left-8 -translate-x-1/2 top-8 w-4 h-4 rounded-full bg-purple-600 hidden md:block" />
              <ProcessCard
                icon={process.icon}
                title={process.title}
                description={process.description}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RFP;