import React from 'react';

const Contact = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-16 bg-gray-50">
      <div className="text-center mb-16">
        <h1 className="text-5xl font-bold text-purple-600 mb-6" style={{ color: '#8A3FFC' }}>
        Contact Us
        </h1>
        <p className="text-2xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
        For more information or assistance, feel free to contact us. We are here to help you make your event more memorable.
        </p>
      </div>
    </div>
  );
};

export default Contact;

