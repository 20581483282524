import React from 'react';
import { 
  Sparkles, 
  Package, 
  ArrowUpDown, 
  Target, 
  Users, 
  Workflow 
} from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white rounded-3xl shadow-md mb-6 p-8 hover:shadow-lg transition-shadow">
    <div className="flex items-start">
      <div className="bg-purple-100 p-4 rounded-2xl mr-6">
        <Icon className="w-6 h-6 text-purple-600" strokeWidth={1.5} />
      </div>
      <div>
        <h2 className="text-xl font-semibold text-gray-900 mb-3">{title}</h2>
        <p className="text-gray-600 text-lg leading-relaxed">{description}</p>
      </div>
    </div>
  </div>
);

const PackageTier = ({ tier, description, color }) => (
  <div className={`bg-${color}-50 border-2 border-${color}-200 rounded-2xl p-6 text-center transition-transform hover:-translate-y-1`}>
    <h3 className={`text-xl font-semibold text-${color}-700 mb-2`}>{tier}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

function EventPackagesExplainer() {
  const features = [
    {
      icon: Sparkles,
      title: "Smart Package Creation",
      description: "The AI creates intelligent event packages based on your preferences and trends from similar events."
    },
    {
      icon: Package,
      title: "Bundled Services",
      description: "These packages bundle services together to give you the best value for your budget."
    },
    {
      icon: ArrowUpDown,
      title: "Flexible Tiers",
      description: "Choose from different package tiers—basic, premium, or luxury—and easily upgrade or downgrade services as needed."
    },
    {
      icon: Target,
      title: "Tailored Experience",
      description: "This ensures a tailored, efficient, and stress-free event planning experience."
    },
    {
      icon: Users,
      title: "Quality Leads",
      description: "It helps service providers receive high-quality, targeted leads."
    },
    {
      icon: Workflow,
      title: "Streamlined Process",
      description: "The AI streamlines the entire process from your initial inquiry to booking, making event planning simple and seamless."
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-6 py-16 bg-gray-50">
      <div className="text-center mb-16">
        <h1 className="text-5xl font-bold text-purple-600 mb-6" style={{ color: '#8A3FFC' }}>
          Intelligent Event Packages
        </h1>
        <p className="text-2xl text-gray-600 max-w-3xl mx-auto leading-relaxed mb-12">
          Discover AI-curated packages tailored to your unique event needs
        </p>

        {/* Package Tiers */}
        <div className="grid md:grid-cols-3 gap-6 mb-16">
          <PackageTier 
            tier="Basic Package" 
            description="Essential services for smaller events"
            color="purple"
          />
          <PackageTier 
            tier="Premium Package" 
            description="Enhanced features for medium-sized events"
            color="purple"
          />
          <PackageTier 
            tier="Luxury Package" 
            description="Complete solution for grand celebrations"
            color="purple"
          />
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-3xl mx-auto grid md:grid-cols-2 gap-6">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </div>

      {/* Call to Action */}
      <div className="text-center mt-12">
        <button className="bg-purple-600 text-white px-8 py-4 rounded-xl hover:bg-purple-700 transition-colors duration-300 font-semibold shadow-lg hover:shadow-xl">
          Explore Package Options
        </button>
      </div>
    </div>
  );
}

export default EventPackagesExplainer;