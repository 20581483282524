import React from 'react';
import { Routes, Route, HashRouter as Router, Link } from 'react-router-dom';
import EventPackagesExplainer from './components/EventPackagesExplainer';
import Contact from './components/Contact';
import RFP from './components/RFP';
import './App.css'; // Import your custom CSS if any
//import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import EventPlannerExplainer from './components/EventPlannerExplainer';
import TextGenerator from './components/TextGenerator';


const App = () => {
  return (
    <Router>
     <header class="flex justify-between items-center p-4 bg-whilte">
  <div class="text-purple-800 text-3xl font-bold">
    <h1 className="text-5xl font-bold text-purple-600 mb-6" style={{ color: '#8A3FFC' }}>
      <a href="/">Reserve Place AI</a>
    </h1>
  </div>
  
  <nav class="flex space-x-8 text-purple-800 text-xl">
    <a href="#/reserve" class="hover:text-purple-600">Reserve a Place</a>
    <a href="#/packages" class="hover:text-purple-600">Intelligent Event Packages</a>
    <a href="#/rfp" class="hover:text-purple-600">Centralized RFP</a>
    <a href="#/iplanner" class="hover:text-purple-600">Personal Event Planner</a>
    <a href="#/contact" class="hover:text-purple-600">Contact Us</a>
  </nav>
</header>

      <div className="container mt-4">
        <Routes>
          <Route path="/" element={<EventPlannerExplainer  />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/packages" element={< EventPackagesExplainer/>} />
          <Route path="/rfp" element={<RFP />} />
          <Route path="/iplanner" element={<EventPlannerExplainer />} />
          <Route path="/reserve" element={<TextGenerator />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;