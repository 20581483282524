import React from 'react';
import { Calendar, Users, Palette, DollarSign, Sparkles } from 'lucide-react';

// Custom Card component using just Tailwind CSS
const Card = ({ children, className }) => (
  <div className={`bg-white rounded-lg shadow-md ${className}`}>
    {children}
  </div>
);

const FeatureCard = (props) => (
  <Card className="hover:shadow-lg transition-shadow duration-300">
    <div className="p-6">
      <div className="flex items-start space-x-4">
        <div className="p-2 bg-purple-100 rounded-lg">
          {React.createElement(props.icon, { 
            className: "w-6 h-6 text-purple-600" 
          })}
        </div>
        <div>
          <h3 className="font-semibold text-lg mb-2">{props.title}</h3>
          <p className="text-gray-600">{props.description}</p>
        </div>
      </div>
    </div>
  </Card>
);


function EventPlannerExplainer() {
  const features = [
    {
      icon: Sparkles,
      title: "Personalized Planning",
      description: "Your AI assistant understands your unique needs and preferences to create the perfect event experience."
    },
    {
      icon: Calendar,
      title: "Smart Recommendations",
      description: "Get tailored suggestions for venues, decorators, and entertainment based on your event type."
    },
    {
      icon: Users,
      title: "Scale-Appropriate",
      description: "Whether it's an intimate gathering or a grand celebration, receive recommendations that match your event size."
    },
    {
      icon: DollarSign,
      title: "Budget-Conscious",
      description: "Find the perfect vendors and services that align with your budget constraints."
    },
    {
      icon: Palette,
      title: "Style Matching",
      description: "Get suggestions that perfectly match your desired event style and theme."
    }
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-12 bg-gray-50">
      <div className="text-center mb-12">
      <h1 className="text-5xl font-bold text-purple-600 mb-6" style={{ color: '#8A3FFC' }}>
          Your Personal AI Event Planner
        </h1>
        <p className="text-2xl text-gray-600 max-w-3xl mx-auto leading-relaxed mb-12">
          Let our AI assistant transform your event planning experience with personalized recommendations and expert guidance.
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-6 mb-12">
        {features.map((feature, index) => (
          <FeatureCard 
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </div>

      <div className="text-center">
        <button 
          className="bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 transition-colors duration-300 font-semibold"
          onClick={() => console.log('Start planning clicked')}
        >
          Start Planning Your Event
        </button>
      </div>
    </div>
  );
}

export default EventPlannerExplainer;